<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Draft">
        <Draft></Draft>
      </vs-tab>
      <vs-tab label="Complete">
        <Complete></Complete>
      </vs-tab>
      <vs-tab label="Cancel">
        <Cancel></Cancel>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Draft from "./_tabs/Draft.vue";
import Complete from "./_tabs/Completed.vue"
import Cancel from "./_tabs/Cancel.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Draft,
    Complete,
    Cancel,
  },
  data() {
    return {
      title: "Batch ED (Per HU)",
    };
  },
};
</script>
